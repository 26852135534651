//Colors

$primaryBlue: #0dc1b9;
$secondaryBlue: #21d9d0;
$secondaryGray: #2a2f35;
$white: #ffffff;
$red: #BD6365;
$lightGray: #ebebeb;
$blue: #547b99;
$brown: #a49995;
$green: #8eb588;
$aqua: #5ec2d7;
$darkBrown: #656362;
$lightRed: #db7b7d;
$yellow: #ea940e;
$green: #1a8c25;

//Sizes
$xxs: 5px;
$xs: 7px;
$s: 10px;
$m: 15px;
$l: 35px;
$paginationFontSize: 15px;
$sideBarWidth: 180px;
$tableFieldVerticalPadding: 9px;
$inputVerticalPadding: 7px;
$textS: 10px;
$textM: 12px;
$textL: 24px;
$textXL: 28px;
$dialogMessage: 16px;
$boldTitle: 15px;
$headerBarHeight: 30px;
$primaryButtonHeight: 21px;
$statisticTitle: 25px;
$statisticDescription: 15px;
$contactIconSize: 20px;
$statisticBlockHeight: 100px;
$statisticIcon: 30px;
$tableButton: 30px;
$tableButtonIcon: 15px;
$sideBarIcon: 20px;
$avatarUserPreview: 120px;
$avatarMessagePreview: 50px;
$footerLogo: 20px;
$checkboxButton: 24px;
$checkboxButtonIcon: 12px;
$dialogTableHeight: 300px;
$contactsHeight: 195px;
$contactSize: 14px;

#dialog {
  z-index: 9000;
}

#dialog-large {
  z-index: 5000;

  &>div>div>div>.input-label {
    font-size: $boldTitle;
    font-weight: bold;
    margin-top: $l;
    display: block;
  }
  textarea {
    height: 70px;
  }
}

h1 {
  margin-top: $m;
  font-size: $textXL;
  font-weight: 600;
  letter-spacing: 3px;
  padding: 0;
  text-transform: uppercase;
  margin-bottom: 0;
  flex-direction: row;
}

h2 {
  font-size: $textL;
  font-weight: 100;
  letter-spacing: 3px;
  padding: 0;
  margin-bottom: 2px;
}

hr {
  margin: 0;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding: .5em;
  padding-right: 1.5em
}

button {
  background: none;
  box-shadow: none;
  border: none;
  outline: inherit;
}

a {
  color: $primaryBlue;
  cursor: pointer;

  &:hover {
    color: $secondaryBlue;
  }
}

.url {
  color: $primaryBlue;
  cursor: pointer;

  &:hover {
    color: $secondaryBlue;
  }
}

.eclipse {
  z-index: 500;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.lds-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: $white;
  }

  &:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  &:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  &:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  &:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  &:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  &:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  &:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  &:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  &:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }

  &:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }

  &:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }

  &:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.yellow-text {
  color: $yellow;
}

.green-text {
  color: $green;
}

.red-text {
  color: $red;
}

.status {
  font-size: $textL;
}

.full-height {
  height: 100%;
}

.dialog-title-large {
  margin-top: $m;
  font-size: $textL;
  font-weight: bold;
  letter-spacing: 3px;
}

.dialog-title {
  margin-top: $m;
  font-size: $textL;
  font-weight: bold;
  letter-spacing: 3px;
}

.login-form {
  width: 350px;
  background-color: $lightGray;
  padding-left: $m;
  padding-right: $m;
}

.login-form-container {
  margin-left: -$sideBarWidth;
  display: flex;
}

.white-input-field {
  border-radius: 0;
  padding-top: $inputVerticalPadding;
  padding-bottom: $inputVerticalPadding;
  font-size: $textM;
}

.input-label {
  margin: 0;
  font-weight: 600;
  font-size: $textM;
}

.side-bar-container {
  z-index: 100;
  position: fixed;
  background-color: $secondaryGray;
  width: $sideBarWidth;
  height: 1000vw;

  div {
    align-items: center;

    &:hover {
      cursor: pointer;

      span, svg {
        color: $primaryBlue
      }
    }
  }

  a {
    display: flex;

    &:hover {
      text-decoration: none;
    }
  }

  .active {
    cursor: pointer;

    span, svg {
      color: $primaryBlue
    }
  }
}

.main-container {
  background-color: $white;
}

.primary-button-container {
  width: 100%;
  cursor: pointer;
  background-color: $primaryBlue;
  color: $white;
  padding-top: $inputVerticalPadding;
  padding-bottom: $inputVerticalPadding;
  justify-content: center;
  display: flex;


  &:hover {
    background-color: $secondaryBlue;
  }
}

.primary-button-text {
  font-size: $textM;
  text-transform: uppercase;
}

.margin-bottom-m {
  margin-bottom: $l;
}

.margin-top-m {
  margin-top: $l;
}

.line {
  height: 1px;
  background-color: $white;
}

.dialog-small-container {
  position: fixed;
  width: 390px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $lightGray;
  padding-left: $l;
  padding-right: $l;
}

.header-bar-container {
  z-index: 300;
  background-color: $primaryBlue;
  height: 30px;
  display: inline-block;
  width: 100%;
}

.header-bar-text {
  margin-left: $m;
}

.sign-out-button {
  margin-left: auto;
  margin-right: $m;
  color: $white;
}

.text-button {
  font-size: $textM;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
}

.welcome {
  color: $white;
  font-size: $textM;
  font-weight: normal;
}

.page-container {
  height: auto;
  padding-left: $m;
  padding-right: $m;
  margin-top: $l;
  max-width: 1250px;
  width: 100%;
  align-self: center;
}

.navigator {
  display: flex;
  font-size: 15px;
  font-weight: lighter;
  margin-top: $m;
}

.statistic-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-box-shadow: inset 0px 0px 0px 2px $lightGray;
  -moz-box-shadow: inset 0px 0px 0px 2px $lightGray;
  box-shadow: inset 0px 0px 0px 2px $lightGray;
  border-radius: 200px;
  height: $statisticBlockHeight;
  margin-top: $m;
  flex-wrap: nowrap;
  align-items: center;
}

.statistic-icon-container {
  height: $statisticBlockHeight;
  min-width: $statisticBlockHeight;
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
}

.statistic-text-container {
  margin-right: $l;
  flex-direction: column;
}

.statistic-title {
  word-wrap: break-spaces;
  font-size: $statisticTitle;
}

.statistic-description {
  font-sixe: $statisticDescription;
}

.statistic-icon {
  font-size: $statisticIcon;
  color: $white;
}

.blue {
  background-color: $blue;
}

.brown {
  background-color: $brown;
}

.green {
  background-color: $green;
}

.dark-brown {
  background-color: $darkBrown;
}

.aqua {
  background-color: $aqua;
}

.primary-blue {
  background-color: $primaryBlue;
}

.first-section {
  .primary-button-container {
    position: absolute;
    top: -$m - $xs;
  }

  margin-top: $m;
}

.next-section {
  margin-top: $l;
  margin-bottom: $xxs;
}

.total tbody tr:last-child {
  font-weight: bold;
}

.table-responsive {
  margin-top: $m;
}

.table {
  margin-bottom: 0;
  border: none;


  .total {

    td:last-of-type {
      text-align: left;
    }

    font-weight: bold;
  }

  .total-last-column {
    text-align: right !important;
  }

  td {
    word-break: break-word;
    padding-top: $xxs;
    padding-bottom: $xxs;

  }

  thead {
    background-color: $secondaryGray;
    color: $white;
    font-size: $textM;
    font-weight: normal;

    tr th {
      vertical-align: middle;

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    font-size: $textM;
    font-weight: normal;

    .table-buttons-container > div {
      margin-left: $xs;

      &:first-of-type {
        margin-left: auto;
      }
    }

    tr:nth-child(odd) {
      background-color: $lightGray;
    }

    tr:nth-child(even) {
      background-color: $white;
    }

    tr td {
      vertical-align: middle;

      &:last-child {
        text-align: right;
      }
    }
  }
}

.table-button-container {
  height: $tableButton;
  width: $tableButton;
  border-radius: 20px;
  background-color: $primaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $secondaryBlue;
  }
}

.danger {
  background-color: $red;
}

.table-button-icon {
  font-size: $tableButtonIcon;
  color: $white;
}

.side-bar-icon {
  font-size: $sideBarIcon;
  color: $white;
}

.side-bar-menu {
  margin-top: $m;
  margin-left: $m;
  margin-right: $m;

  &:first-child {
    margin-top: $m + $headerBarHeight;
  }

  span {
    font-size: $textS;
    color: $white;
    text-transform: uppercase;
    margin-left: $xs;
  }

}

.route-container {
  display: flex;
  margin-left: $sideBarWidth;
}

.section-button-container {
  margin-top: $m;
  margin-bottom: $xxs;
}

.dialog-error-message {
  margin-top: $s;
  font-size: $dialogMessage;
  text-align: center;
}

.dialog-button-container {
  margin-top: $m;
  margin-bottom: $m;
}

.pagination-container {
  margin-top: $m;

  ul {
    margin-bottom: 0;
  }

  .pagination {
    .disabled {
      display: none;
    }

    .page-item {
      align-content: center;
      justify-items: center;

    }

    .page-link {
      width: auto;
      color: $secondaryGray;
      border: none;
      border-radius: 0;
      font-weight: normal;
      font-size: $paginationFontSize;

      &:hover {
        background-color: $lightGray;
      }
    }

    .active {
      z-index: 200;

      .page-link {
        background-color: $secondaryGray;
        font-weight: bold;
        color: $white;
      }
    }
  }
}

.details-white {
  border: 2px solid $white
}

.details-container {
  background-color: $lightGray;
  padding: $m;
  width: 100%;
  margin-top: $m;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;

  div {
    div {
      &:not(:first-child) {
        margin-top: $m;
      }

      strong {
        font-size: $boldTitle;
        margin: 0;
        padding: 0;
      }

      p {
        padding: 0;
        margin-top: $xxs;
        margin-bottom: 0;
        font-size: $textM;
        word-break: break-word;
      }
    }
  }
}

.avatar-user-preview {
  height: $avatarUserPreview;
  width: $avatarUserPreview;
  border-radius: 100px;
}

.avatar-message-preview {
  height: $avatarMessagePreview;
  width: $avatarMessagePreview;
  border-radius: 100px;
}

.contact-icon {
  align-content: start;
  font-size: $contactIconSize;
  min-width: 50px;
}

.line-short {
  width: 30px;
  height: 1px;
  align-self: center;
  margin-top: 0 !important;
  background-color: $secondaryGray;
}

.danger-button {
  background-color: $red;

  &:hover {
    background-color: $lightRed;
  }
}

.details-button {
  margin-left: $s;
}

.details-button-container {
  flex-wrap: nowrap;
  margin-top: $m;
}

.dialog-large-container {
  min-width: 390px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $lightGray;
  padding-left: $l;
  padding-right: $l;

  .total {
    height: $tableButton + $xs;
  }

  .dialog-button-container {
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    max-width: 700px;
  }
}

.no-items-message {
  color: $secondaryGray;
  font-size: $textM;
  margin-top: $m;
}

.footer {
  p {
    padding-bottom: $m !important;
    margin-bottom: 0 !important;
  }

  margin-top: $l !important;
}

.footer-text {
  font-size: $textM;
}

.footer-logo {
  height: $footerLogo;
  width: $footerLogo;
}

.messages-container {
  color: $secondaryGray;

  & > span {
    display: block;
    text-align: center;
    font-size: $textM;
    padding-top: $xs;
  }

  & > div {
    border: 2px solid $lightGray;
    padding: $m;
    margin-top: $m;
    border-radius: 20px;
  }

  .message-date {
    font-size: $textM;
  }
}

.messages-user-name-left {
  font-size: $boldTitle;
  font-weight: bold;
  margin-left: $xs;
}

.messages-user-name-right {
  font-size: $boldTitle;
  font-weight: bold;
  margin-right: $xs;
}

.message-text {
  padding-top: $m;
  font-size: $textM;
}

.message-file {
  margin-top: $xxs;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1 1 100%;
  font-size: $textM;
  color: $primaryBlue !important;
  word-wrap: break-word;
  overflow: auto;

  &:hover {
    font-size: $textM;
    color: $primaryBlue;
    text-decoration: none;
  }
}

.message-file-container {
  padding-top: $s;
}

.advance-details {
  span {
    font-size: $textM;
    white-space: break-spaces;
  }
  a {
    color: $primaryBlue !important;
    font-size: $textM;
  }
}

.table-buttons-container {
  display: flex;
  flex-direction: row;
}

.dialog-category-title {
  font-size: $boldTitle;
  font-weight: bold;
  margin-top: $l;
  display: block;
}

.checkbox {
  background-color: $white;
  border: 1px solid $lightGray;
  border-radius: 50px;
  height: $checkboxButton;
  width: $checkboxButton;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  .checkbox-icon {
    color: $primaryBlue;
    font-size: $checkboxButtonIcon;
  }
}

.table-dialog {
  width: 700px;

  .table {
    margin-top: 0;
  }

  margin-top: $xs;
  display: block;
  max-height: $dialogTableHeight;
  overflow-y: auto;
}

.description-button-container {
  margin-left: $m;
  margin-top: $m + $xxs;

  span {
    margin-left: $l;
    margin-right: $l;
  }
}

.conflict-user-container {
  margin-top: $m;
  color: $secondaryGray;
  flex-wrap: nowrap;

  div > span:first-of-type {
    font-size: $boldTitle;
    font-weight: bold;
    display: flex;
    margin-top: $xs;
  }

  div > span:not(:first-of-type) {
    word-wrap: break-spaces;
    overflow: inherit;
    font-size: $textM;
    display: flex;
  }

  .contacts-container {
    height: $contactsHeight !important;

    div {
      height: 100%;

      div {
        height: auto;
        flex-direction: row;
        justify-content: flex-start;
      }

      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    height: auto;

    span {
      margin-top: 0 !important;
    }
  }
}

.comment-container {
  margin-top: $m;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  width: 500px;

  textarea {
    height: 150px;
  }
}

.comment {
  padding: 0;
  margin-top: $xxs;
  margin-bottom: 0;
  font-size: $textM;
  color: $secondaryGray;
}

.page-404-logout {
  margin-right: $sideBarWidth;
}

.page-404 {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: $textM;
  height: 100%;
  color: $secondaryGray;
  text-align: center;

  h1 {
    font-size: 250px;
    font-weight: bold;
  }

  h3 {
    margin-bottom: 0;
  }

  hr {
    margin-top: $m;
    margin-bottom: $m;
    width: 30%
  }
}

.contacts {
  font-size: $contactSize;

  span {
    font-weight: normal !important;
  }
}

.items-count {
  text-align: right;
  justify-self: right;
  align-self: center;
  font-size: $boldTitle;
  color: $secondaryGray;
}

.radio {
  display: flex;
  align-items: center;
  margin: $xs 0 0 0;

  label {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
  }

  input {
    width: 19px;
    height: 19px;
    margin: 0 $xs 0 0;
  }
}

.disabled-form-button {
  opacity: 0.3;
  height: $tableButton;
  width: $tableButton;
  border-radius: 20px;
  background-color: $primaryBlue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-admin-button {
  margin-top: $l;
}

.text-danger {
  color: $red !important;
}

.description {
  padding: 0;
  margin-top: $xxs;
  margin-bottom: 0;
  font-size: $textM;
}

.invert-button-danger {
  background-color: $white !important;
  font-weight: bold;
  webkit-box-shadow: inset 0px 0px 0px 2px $red;
  -moz-box-shadow: inset 0px 0px 0px 2px $red;
  box-shadow: inset 0px 0px 0px 2px $red;
  color: $red !important;

  &:hover {
    webkit-box-shadow: inset 0px 0px 0px 2px $lightRed;
    -moz-box-shadow: inset 0px 0px 0px 2px $lightRed;
    box-shadow: inset 0px 0px 0px 2px $lightRed;
    color: $lightRed !important;
  }
}

.invert-button {
  background-color: $white !important;
  font-weight: bold;
  webkit-box-shadow: inset 0px 0px 0px 2px $primaryBlue;
  -moz-box-shadow: inset 0px 0px 0px 2px $primaryBlue;
  box-shadow: inset 0px 0px 0px 2px $primaryBlue;
  color: $primaryBlue !important;

  &:hover {
    webkit-box-shadow: inset 0px 0px 0px 2px $secondaryBlue;
    -moz-box-shadow: inset 0px 0px 0px 2px $secondaryBlue;
    box-shadow: inset 0px 0px 0px 2px $secondaryBlue;
    color: $secondaryBlue !important;
  }
}

@media (min-width: 1200px) {
  .table-dialog {
    width: 1200px;
  }
}
