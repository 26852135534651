html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    font-family: 'Lato', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    height:100%;
}
